import React from "react";
import { Page } from "../../components/Page";
import { Title } from "../../components/Title";
import { Content } from "../../components/Content";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SlippingPreventionPage = () => (
  <Page language="en" page="Avoid slipping">
    <Row>
      <Col>
        <Title>Reduce the risk of slipping</Title>

        <Content>
          Slipping accidents of pedestrians are very common. The severity of the
          slipping accidents vary from very mild to severe, even fatal. Slipping
          often causes sprain of the ankle but often the bones are highly
          strained too. During falls, especially the wrist and hip area bones
          are easily fractured. The back may even be strained due to a sudden
          correction movement of the balance. The risk of disabling grows with
          the age. Already at the age of 40-50, the risk is double compared to
          that of the 20-29 -year-old.
        </Content>

        <Content>Ways to avoid slipping:</Content>

        <Content as="ol">
          <li>
            Walk on a slippery ground WITH CAUTION, as all places may not be
            covered by sand or the sand may have sunk inside the ice.
          </li>
          <li>
            Choose footwear suitable for winter conditions and use ANTI-SLIP
            OVERSHOES.
          </li>
        </Content>

        <Content>
          <a href="http://www.kotitapaturma.fi/en/">
            Website on prevention campaign of domestic accidents
          </a>
        </Content>
      </Col>
    </Row>
  </Page>
);

export default SlippingPreventionPage;
